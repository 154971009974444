.services__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    text-align: center;
    padding: 2rem;
}

.service {
    background: var(--color-bg-variant);
    border-radius: 0rem 1rem 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transitoon);
}

.service:hover {
    background: var(--color-stars-light);
    border: 1px solid var(--color-primary);
    border-color: var(--color-primary-variant);
    cursor: default;
}

.service__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0rem 1rem 2rem 2rem;
    box-shadow: 0 2rem 1 rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
    color: var(--color-bg);
    font-size: 1.25rem;
    text-align: center;
}

.service__list {
  padding: 2rem;
  margin-left: 1rem;  
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service__list-icon {
    color: var(--color-primary);
    margin-top: 5.5px;
}

.service__list p {
    font-size: 0.9rem;
}



/***************************[L] MEDIA QUERIES***************************/
@media screen and (min-width: 2501px) and (max-width: 4000px) {

}

/***************************[M] MEDIA QUERIES***************************/
@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: 1fr;
        max-width: 75%;
    }

    .service__list {
        font-size: 0.9rem;
        margin-left: 4rem;
    }
}

/***************************[S] MEDIA QUERIES***************************/
@media screen and (max-width: 600px) {
    .services__container {
        gap: 1.5rem;
        max-width: 90%;
    }

    .service__list {
        font-size: 0.9rem;
        margin-left: 0rem;
    }
}
header {
    height: 90vh;
    padding-top: 7rem;
    overflow: hidden;
    align-items: center;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header__container span {
    color: var(--color-primary);
    transition: var(--transition);
}



/******************CTA******************/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}



/******************ME******************/
.me {
    background: linear-gradient(var(--color-primary), var(--color-shirt));
    // background: linear-gradient(var(--color-primary), rgb(219, 219, 219));
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 5rem 5rem;
    overflow: hidden;
    padding: 0rem 0rem 1rem 2rem;
}



/******************HeaderSocials******************/
.header__socials::before {
    content: "";
    width: 1px;
    height: 5rem;
    background: var(--color-primary);
}


.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 2rem;
    bottom: 29rem; 
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 5rem;
    background: var(--color-primary);
}



/******************ScollDown******************/
.scroll__down {
    position: absolute;
    right: 2rem;
    bottom: 29rem; 
    font-size:0.9rem;
    writing-mode: vertical-rl;
    text-orientation: upright;
}



/***************************[M] MEDIA QUERIES***************************/
@media screen and (max-width: 1024px) {
    .header__socials,
    .scroll__down {
        bottom: 5rem;
    }
    header {
        height: 72vh;
    }
}



/***************************[S] MEDIA QUERIES***************************/
@media screen and (max-width: 600px) {
    header {
        height: 125vh;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/******************CTA******************/
.ctas {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*****************Buttons******************/
// a {
//   color: var(--color-primary);
//   transition: var(--transition);
// }

// a:hover {
//   color: var(--color-white);
// }

// .btn {
//   width: max-content;
//   display: inline-block;
//   color: var(--color-primary);
//   padding: 0.75rem 1.2rem;
//   border-radius: 0.4rem;
//   cursor: pointer;
//   border: 1px solid var(--color-primary);
//   transition: var(--transition);
// }

// .btn:hover {
//   background: var(--color-white);
//   color: var(--color-bg);
// }

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

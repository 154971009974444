@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c; //#2c2c6c    #374657
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --containter-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;

  --color-stars-light: #1b2735;
  --color-stars-dark: #090a0f;

  --color-shirt: #e4ddca;
  --color-transparent: rgba(0, 0, 0, 0);
}

html {
  scroll-behavior: smooth;
  height: 100%;
  // overflow: hidden;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  background-attachment: fixed;
}

::-webkit-scroll {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  // background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  // background-image: url(../src/assets/bg-texture.png);
}

/********************DEF STYLES***************************/
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5;
}

section {
  margin-top: 8rem;
  // height: 50vh;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-white);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-primary);
  transition: var(--transition);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/***************************[L] MEDIA QUERIES***************************/
@media screen and (min-width: 2501px) and (max-width: 4000px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 30rem;
  }
}

/***************************[M] MEDIA QUERIES***************************/
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 30rem;
  }
}

/***************************[S] MEDIA QUERIES***************************/
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-top: 2rem;
  }
}

/***************************Stars***************************/

#stars1 {
  background-attachment: fixed;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1500px 590px #fff, 1723px 924px #fff, 2757px 1946px #fff,
    1530px 1358px #fff, 2127px 1520px #fff, 2461px 664px #fff, 1835px 828px #fff,
    1652px 765px #fff, 1036px 212px #fff, 2043px 1173px #fff, 1211px 1864px #fff,
    393px 1273px #fff, 784px 1744px #fff, 267px 1905px #fff, 2202px 1760px #fff,
    2987px 1004px #fff, 59px 1862px #fff, 1203px 1796px #fff, 2378px 1260px #fff,
    567px 1554px #fff, 1620px 950px #fff, 692px 804px #fff, 1108px 1139px #fff,
    2223px 1208px #fff, 2525px 1612px #fff, 1884px 1653px #fff,
    1899px 551px #fff, 1340px 1414px #fff, 490px 1890px #fff, 2594px 1363px #fff,
    1292px 429px #fff, 182px 1454px #fff, 181px 44px #fff, 1768px 444px #fff,
    284px 1527px #fff, 333px 1441px #fff, 2320px 1008px #fff, 2197px 1975px #fff,
    1659px 1107px #fff, 1776px 1631px #fff, 2410px 1114px #fff,
    2996px 475px #fff, 241px 216px #fff, 1164px 1321px #fff, 2106px 775px #fff,
    815px 477px #fff, 114px 214px #fff, 911px 1952px #fff, 451px 1397px #fff,
    709px 1102px #fff, 319px 78px #fff, 226px 1561px #fff, 851px 1570px #fff,
    1295px 1241px #fff, 831px 1142px #fff, 2498px 1208px #fff,
    2969px 1538px #fff, 1250px 619px #fff, 2324px 1495px #fff, 1256px 368px #fff,
    2664px 532px #fff, 1155px 1376px #fff, 929px 780px #fff, 2192px 1540px #fff,
    1086px 394px #fff, 2533px 1968px #fff, 117px 1262px #fff, 1802px 1246px #fff,
    2133px 1249px #fff, 432px 1567px #fff, 608px 1203px #fff, 1509px 1463px #fff,
    1353px 1008px #fff, 1863px 454px #fff, 2066px 999px #fff, 732px 1252px #fff,
    279px 583px #fff, 769px 912px #fff, 66px 1587px #fff, 638px 390px #fff,
    1661px 470px #fff, 404px 450px #fff, 1100px 229px #fff, 2356px 963px #fff,
    2447px 1238px #fff, 979px 1748px #fff, 39px 1441px #fff, 536px 663px #fff,
    557px 1186px #fff, 787px 1898px #fff, 2666px 742px #fff, 1903px 50px #fff,
    1612px 756px #fff, 1686px 1260px #fff, 2011px 1897px #fff, 2651px 460px #fff,
    1763px 262px #fff, 1696px 870px #fff, 234px 1080px #fff, 1698px 66px #fff,
    96px 179px #fff, 2093px 1784px #fff, 1824px 1836px #fff, 1711px 1079px #fff,
    2161px 174px #fff, 1390px 1557px #fff, 2855px 1211px #fff,
    2892px 1896px #fff, 309px 658px #fff, 1671px 904px #fff, 898px 1903px #fff,
    2741px 738px #fff, 2759px 316px #fff, 1998px 323px #fff, 581px 696px #fff,
    1908px 47px #fff, 580px 1120px #fff, 1778px 1452px #fff, 2998px 1679px #fff,
    1848px 900px #fff, 1228px 1720px #fff, 1234px 903px #fff, 28px 1324px #fff,
    1113px 376px #fff, 1555px 1172px #fff, 2965px 26px #fff, 1130px 1827px #fff,
    147px 527px #fff, 2938px 919px #fff, 2402px 665px #fff, 1528px 1591px #fff,
    1042px 1648px #fff, 1465px 241px #fff, 2430px 46px #fff, 46px 1200px #fff,
    2698px 1541px #fff, 1807px 1931px #fff, 1064px 1338px #fff,
    2738px 1562px #fff, 1142px 131px #fff, 2746px 977px #fff, 172px 78px #fff,
    2292px 1437px #fff, 516px 1711px #fff, 2831px 157px #fff, 895px 914px #fff,
    212px 1420px #fff, 791px 1610px #fff, 1012px 535px #fff, 2653px 248px #fff,
    2752px 539px #fff, 1179px 1228px #fff, 2020px 368px #fff, 349px 1792px #fff,
    2024px 1804px #fff, 500px 391px #fff, 412px 1823px #fff, 29px 790px #fff,
    611px 1096px #fff, 2412px 1868px #fff, 1191px 534px #fff, 2051px 1613px #fff,
    1002px 1513px #fff, 2327px 483px #fff, 1226px 761px #fff, 567px 28px #fff,
    2868px 1590px #fff, 182px 1331px #fff, 2190px 1902px #fff, 640px 667px #fff,
    301px 657px #fff, 1310px 451px #fff, 2464px 1683px #fff, 1004px 1322px #fff,
    2997px 399px #fff, 1808px 1808px #fff, 2349px 1313px #fff, 223px 991px #fff,
    692px 1414px #fff, 276px 475px #fff, 887px 1183px #fff, 1649px 785px #fff,
    1517px 559px #fff, 1494px 913px #fff, 593px 179px #fff, 2984px 1685px #fff,
    2605px 301px #fff, 1767px 1447px #fff, 2409px 700px #fff, 217px 1491px #fff,
    1806px 1496px #fff, 21px 841px #fff, 2385px 1741px #fff, 900px 1082px #fff,
    1778px 1840px #fff, 1514px 814px #fff, 2150px 597px #fff, 292px 1478px #fff,
    1794px 1502px #fff, 960px 1973px #fff, 1078px 1522px #fff, 1043px 281px #fff,
    562px 1249px #fff, 2165px 299px #fff, 2765px 700px #fff, 2677px 158px #fff,
    1709px 453px #fff, 113px 1501px #fff, 2520px 1930px #fff, 2734px 765px #fff,
    1390px 946px #fff, 2772px 1501px #fff, 713px 1760px #fff, 1954px 1005px #fff,
    1615px 297px #fff, 267px 375px #fff, 2015px 175px #fff, 2260px 984px #fff,
    1042px 1124px #fff, 782px 43px #fff, 1148px 257px #fff, 636px 769px #fff,
    1146px 802px #fff, 1512px 1439px #fff, 2267px 170px #fff, 2119px 996px #fff,
    1241px 1817px #fff, 2143px 929px #fff, 1720px 944px #fff, 347px 1261px #fff,
    263px 1699px #fff, 1934px 505px #fff, 2300px 866px #fff, 1279px 661px #fff,
    384px 1167px #fff, 15px 750px #fff, 2416px 55px #fff, 2293px 278px #fff,
    2803px 1321px #fff, 899px 295px #fff, 2387px 1534px #fff, 446px 1635px #fff,
    1744px 963px #fff, 1358px 1299px #fff, 2801px 500px #fff, 1157px 1071px #fff,
    2936px 1833px #fff, 402px 1134px #fff, 802px 1469px #fff, 2236px 1169px #fff,
    2819px 495px #fff, 702px 118px #fff, 1093px 950px #fff, 2846px 1570px #fff,
    1948px 591px #fff, 1720px 1659px #fff, 1110px 742px #fff, 350px 880px #fff,
    1795px 1591px #fff, 530px 752px #fff, 2873px 513px #fff, 748px 1529px #fff,
    1930px 85px #fff, 870px 1447px #fff, 1357px 811px #fff, 997px 1478px #fff,
    1785px 1px #fff, 2014px 95px #fff, 2130px 757px #fff, 1706px 1432px #fff,
    1072px 1555px #fff, 2275px 1153px #fff, 1554px 851px #fff, 1597px 39px #fff,
    882px 637px #fff, 75px 539px #fff, 2115px 371px #fff, 324px 547px #fff,
    1264px 586px #fff, 2985px 10px #fff, 2628px 60px #fff, 758px 337px #fff,
    717px 1487px #fff, 2782px 1392px #fff, 2050px 730px #fff, 2381px 1520px #fff,
    2717px 32px #fff, 355px 1130px #fff, 981px 1494px #fff, 2798px 1509px #fff,
    1903px 1534px #fff, 568px 561px #fff, 538px 52px #fff, 713px 1098px #fff,
    988px 229px #fff, 115px 394px #fff, 1903px 1524px #fff, 1613px 602px #fff,
    1117px 1149px #fff, 2730px 1309px #fff, 2842px 868px #fff, 977px 1563px #fff,
    2584px 1447px #fff, 1388px 1302px #fff, 864px 588px #fff, 550px 950px #fff,
    882px 1545px #fff, 2948px 72px #fff, 2307px 1832px #fff, 1184px 896px #fff,
    899px 258px #fff, 2959px 1282px #fff, 1585px 799px #fff, 2589px 1857px #fff,
    1411px 1375px #fff, 1708px 701px #fff, 2762px 416px #fff, 824px 1790px #fff,
    2650px 416px #fff, 2891px 1903px #fff, 2364px 874px #fff, 385px 1404px #fff,
    1399px 238px #fff, 2194px 1365px #fff, 416px 714px #fff, 2722px 1952px #fff,
    2737px 974px #fff, 373px 1746px #fff, 2494px 1535px #fff, 287px 826px #fff,
    617px 1455px #fff, 2921px 1243px #fff, 2749px 1561px #fff,
    1755px 1464px #fff, 887px 1633px #fff, 103px 1141px #fff, 806px 719px #fff,
    2783px 1715px #fff, 1030px 1983px #fff, 252px 823px #fff, 472px 1262px #fff,
    289px 604px #fff, 1687px 1964px #fff, 230px 958px #fff, 1555px 1260px #fff,
    1283px 1335px #fff, 596px 1550px #fff, 2963px 990px #fff, 1932px 1048px #fff,
    710px 191px #fff, 41px 503px #fff, 1988px 1210px #fff, 156px 1569px #fff,
    1184px 660px #fff, 197px 1688px #fff, 2653px 1430px #fff, 627px 593px #fff,
    2464px 1839px #fff, 2877px 928px #fff, 380px 851px #fff, 2986px 487px #fff,
    403px 1554px #fff, 1586px 632px #fff, 1688px 91px #fff, 1378px 315px #fff,
    1184px 44px #fff, 85px 446px #fff, 2559px 1395px #fff, 1234px 1129px #fff,
    1536px 338px #fff, 1883px 1469px #fff, 621px 404px #fff, 1511px 1050px #fff,
    1089px 1793px #fff, 565px 408px #fff, 1825px 1011px #fff, 995px 1501px #fff,
    1400px 981px #fff, 1336px 171px #fff, 741px 59px #fff, 114px 1858px #fff,
    1332px 1353px #fff, 1896px 1608px #fff, 1899px 444px #fff, 1636px 394px #fff,
    986px 1816px #fff, 2853px 1900px #fff, 1286px 1075px #fff, 2128px 374px #fff,
    2343px 662px #fff, 244px 1055px #fff, 1885px 556px #fff, 2278px 1515px #fff,
    2832px 1253px #fff, 1031px 822px #fff, 1554px 509px #fff, 2413px 436px #fff,
    192px 161px #fff, 2303px 474px #fff, 1348px 1608px #fff, 2706px 472px #fff,
    2329px 918px #fff, 1958px 1987px #fff, 619px 1855px #fff, 2344px 1699px #fff,
    2932px 736px #fff, 881px 775px #fff, 1764px 524px #fff, 1912px 1717px #fff,
    2147px 1864px #fff, 1954px 1174px #fff, 2851px 1453px #fff,
    2204px 254px #fff, 2965px 590px #fff, 2770px 1733px #fff, 2393px 438px #fff,
    863px 1105px #fff, 888px 851px #fff, 2414px 206px #fff, 1176px 1915px #fff,
    10px 1629px #fff, 1105px 1683px #fff, 2179px 437px #fff, 815px 720px #fff,
    2033px 1823px #fff, 1159px 389px #fff, 1095px 144px #fff, 2812px 1697px #fff,
    1989px 804px #fff, 28px 935px #fff, 2458px 1004px #fff, 2907px 1298px #fff,
    789px 236px #fff, 997px 1589px #fff, 2156px 1246px #fff, 2316px 1639px #fff,
    827px 1792px #fff, 1650px 1014px #fff, 2871px 1503px #fff, 2596px 511px #fff,
    94px 49px #fff, 376px 1881px #fff, 987px 948px #fff, 204px 816px #fff,
    316px 750px #fff, 311px 335px #fff, 2793px 1272px #fff, 2920px 644px #fff,
    1455px 1594px #fff, 1988px 412px #fff, 524px 999px #fff, 1841px 400px #fff,
    1697px 53px #fff, 657px 1184px #fff, 357px 724px #fff, 2615px 1118px #fff,
    134px 478px #fff, 2343px 408px #fff, 486px 1730px #fff, 1697px 1690px #fff,
    263px 1122px #fff, 2435px 1571px #fff, 577px 145px #fff, 563px 381px #fff,
    2397px 1164px #fff, 461px 563px #fff, 1469px 1099px #fff, 847px 146px #fff,
    1859px 1328px #fff, 2209px 1732px #fff, 1765px 1340px #fff,
    1315px 1374px #fff, 2719px 456px #fff, 1257px 457px #fff, 54px 463px #fff,
    670px 418px #fff, 2450px 1786px #fff, 2207px 109px #fff, 119px 1235px #fff,
    928px 1364px #fff, 2345px 1983px #fff, 1470px 355px #fff, 2965px 1661px #fff,
    369px 335px #fff, 2969px 844px #fff, 1996px 1071px #fff, 819px 185px #fff,
    300px 494px #fff, 2272px 1474px #fff, 1497px 55px #fff, 1516px 487px #fff,
    856px 1750px #fff, 535px 331px #fff, 697px 1961px #fff, 1085px 297px #fff,
    1642px 1052px #fff, 2310px 1945px #fff, 1461px 1150px #fff,
    2074px 1530px #fff, 724px 1054px #fff, 2719px 1382px #fff, 599px 44px #fff,
    715px 299px #fff, 202px 821px #fff, 1683px 461px #fff, 2474px 310px #fff,
    1623px 905px #fff, 758px 1564px #fff, 23px 1776px #fff, 219px 102px #fff,
    1237px 858px #fff, 2704px 1098px #fff, 2212px 692px #fff, 2729px 1069px #fff,
    1251px 862px #fff, 179px 1260px #fff, 1841px 836px #fff, 1563px 1739px #fff,
    2818px 1725px #fff, 1711px 1606px #fff, 303px 336px #fff, 2268px 1288px #fff,
    2835px 1799px #fff, 416px 1487px #fff, 1249px 1416px #fff, 2276px 229px #fff,
    1085px 1053px #fff, 2719px 1815px #fff, 2849px 1462px #fff,
    2356px 1100px #fff, 1884px 1409px #fff, 437px 574px #fff, 2346px 75px #fff,
    1602px 1151px #fff, 2335px 838px #fff, 2675px 184px #fff, 2107px 1428px #fff,
    2350px 1458px #fff, 375px 12px #fff, 718px 395px #fff, 187px 1402px #fff,
    2430px 828px #fff, 622px 1284px #fff, 1309px 1770px #fff, 1322px 1658px #fff,
    1758px 414px #fff, 528px 1373px #fff, 2664px 628px #fff, 1480px 1027px #fff,
    2900px 932px #fff, 1502px 1658px #fff, 648px 1502px #fff, 852px 960px #fff,
    1905px 991px #fff, 275px 407px #fff, 1822px 197px #fff, 1383px 239px #fff,
    1813px 833px #fff, 2832px 511px #fff, 21px 721px #fff, 645px 1007px #fff,
    2496px 1756px #fff, 2024px 461px #fff, 199px 1858px #fff, 245px 500px #fff,
    2351px 1432px #fff, 167px 2000px #fff, 711px 1674px #fff, 1743px 1227px #fff,
    836px 726px #fff, 1371px 163px #fff, 2530px 312px #fff, 1054px 421px #fff,
    2616px 1184px #fff, 2366px 1323px #fff, 433px 279px #fff, 1716px 990px #fff,
    1259px 643px #fff, 294px 232px #fff, 2724px 701px #fff, 2452px 1603px #fff,
    399px 1210px #fff, 327px 1029px #fff, 317px 1070px #fff, 787px 1396px #fff,
    477px 255px #fff, 1726px 1802px #fff, 2884px 1455px #fff, 2947px 1025px #fff,
    2509px 1061px #fff, 1485px 1505px #fff, 594px 1267px #fff, 2036px 936px #fff,
    659px 631px #fff, 1315px 45px #fff, 2494px 1767px #fff, 847px 1605px #fff,
    342px 1640px #fff, 1607px 1754px #fff, 1845px 219px #fff, 1935px 1160px #fff,
    1701px 22px #fff, 2992px 1773px #fff, 1349px 6px #fff, 1275px 864px #fff,
    227px 1325px #fff, 2031px 869px #fff, 494px 362px #fff, 510px 1957px #fff,
    1506px 1818px #fff, 987px 654px #fff, 753px 1174px #fff, 2685px 110px #fff,
    1096px 237px #fff, 1265px 68px #fff, 1369px 1719px #fff, 1362px 708px #fff,
    808px 1198px #fff, 2284px 1914px #fff, 1882px 852px #fff, 1354px 1083px #fff,
    747px 1945px #fff, 1631px 1910px #fff, 2062px 1878px #fff, 671px 262px #fff,
    356px 359px #fff, 782px 69px #fff, 893px 1267px #fff, 1275px 1870px #fff,
    2747px 981px #fff, 1117px 82px #fff, 218px 415px #fff, 753px 1970px #fff,
    1085px 1399px #fff, 1513px 299px #fff, 2067px 1438px #fff,
    2415px 1404px #fff, 2430px 912px #fff, 150px 525px #fff, 1142px 710px #fff,
    323px 877px #fff, 2575px 4px #fff, 1602px 236px #fff, 202px 1811px #fff,
    1751px 137px #fff, 2461px 1965px #fff, 2911px 417px #fff, 205px 68px #fff,
    57px 1485px #fff, 702px 1576px #fff, 1060px 1845px #fff, 702px 22px #fff,
    1215px 1736px #fff, 446px 1017px #fff, 2278px 1801px #fff, 254px 19px #fff,
    241px 773px #fff, 957px 974px #fff, 1652px 1127px #fff, 1156px 819px #fff,
    2281px 1586px #fff, 1016px 1592px #fff, 132px 1258px #fff, 1716px 62px #fff,
    2729px 1849px #fff, 87px 536px #fff, 989px 1760px #fff, 2599px 352px #fff,
    273px 1398px #fff, 1882px 1540px #fff, 520px 1266px #fff, 2854px 60px #fff,
    1080px 1553px #fff, 976px 1243px #fff, 660px 979px #fff, 208px 1347px #fff,
    604px 1269px #fff, 988px 1232px #fff, 875px 1678px #fff, 1299px 1396px #fff,
    2371px 1115px #fff, 1217px 1423px #fff, 55px 1463px #fff, 747px 55px #fff,
    2930px 965px #fff, 1898px 86px #fff, 1909px 964px #fff, 1439px 896px #fff,
    1779px 1839px #fff, 398px 640px #fff, 2818px 1847px #fff, 2059px 243px #fff,
    1866px 1262px #fff, 314px 528px #fff, 1328px 975px #fff, 1500px 1071px #fff,
    1595px 1337px #fff, 905px 696px #fff, 1684px 449px #fff, 97px 72px #fff;
  animation: animStar 50s linear infinite;
}
#stars1:after {
  background-attachment: fixed;
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1500px 590px #fff, 1723px 924px #fff, 2757px 1946px #fff,
    1530px 1358px #fff, 2127px 1520px #fff, 2461px 664px #fff, 1835px 828px #fff,
    1652px 765px #fff, 1036px 212px #fff, 2043px 1173px #fff, 1211px 1864px #fff,
    393px 1273px #fff, 784px 1744px #fff, 267px 1905px #fff, 2202px 1760px #fff,
    2987px 1004px #fff, 59px 1862px #fff, 1203px 1796px #fff, 2378px 1260px #fff,
    567px 1554px #fff, 1620px 950px #fff, 692px 804px #fff, 1108px 1139px #fff,
    2223px 1208px #fff, 2525px 1612px #fff, 1884px 1653px #fff,
    1899px 551px #fff, 1340px 1414px #fff, 490px 1890px #fff, 2594px 1363px #fff,
    1292px 429px #fff, 182px 1454px #fff, 181px 44px #fff, 1768px 444px #fff,
    284px 1527px #fff, 333px 1441px #fff, 2320px 1008px #fff, 2197px 1975px #fff,
    1659px 1107px #fff, 1776px 1631px #fff, 2410px 1114px #fff,
    2996px 475px #fff, 241px 216px #fff, 1164px 1321px #fff, 2106px 775px #fff,
    815px 477px #fff, 114px 214px #fff, 911px 1952px #fff, 451px 1397px #fff,
    709px 1102px #fff, 319px 78px #fff, 226px 1561px #fff, 851px 1570px #fff,
    1295px 1241px #fff, 831px 1142px #fff, 2498px 1208px #fff,
    2969px 1538px #fff, 1250px 619px #fff, 2324px 1495px #fff, 1256px 368px #fff,
    2664px 532px #fff, 1155px 1376px #fff, 929px 780px #fff, 2192px 1540px #fff,
    1086px 394px #fff, 2533px 1968px #fff, 117px 1262px #fff, 1802px 1246px #fff,
    2133px 1249px #fff, 432px 1567px #fff, 608px 1203px #fff, 1509px 1463px #fff,
    1353px 1008px #fff, 1863px 454px #fff, 2066px 999px #fff, 732px 1252px #fff,
    279px 583px #fff, 769px 912px #fff, 66px 1587px #fff, 638px 390px #fff,
    1661px 470px #fff, 404px 450px #fff, 1100px 229px #fff, 2356px 963px #fff,
    2447px 1238px #fff, 979px 1748px #fff, 39px 1441px #fff, 536px 663px #fff,
    557px 1186px #fff, 787px 1898px #fff, 2666px 742px #fff, 1903px 50px #fff,
    1612px 756px #fff, 1686px 1260px #fff, 2011px 1897px #fff, 2651px 460px #fff,
    1763px 262px #fff, 1696px 870px #fff, 234px 1080px #fff, 1698px 66px #fff,
    96px 179px #fff, 2093px 1784px #fff, 1824px 1836px #fff, 1711px 1079px #fff,
    2161px 174px #fff, 1390px 1557px #fff, 2855px 1211px #fff,
    2892px 1896px #fff, 309px 658px #fff, 1671px 904px #fff, 898px 1903px #fff,
    2741px 738px #fff, 2759px 316px #fff, 1998px 323px #fff, 581px 696px #fff,
    1908px 47px #fff, 580px 1120px #fff, 1778px 1452px #fff, 2998px 1679px #fff,
    1848px 900px #fff, 1228px 1720px #fff, 1234px 903px #fff, 28px 1324px #fff,
    1113px 376px #fff, 1555px 1172px #fff, 2965px 26px #fff, 1130px 1827px #fff,
    147px 527px #fff, 2938px 919px #fff, 2402px 665px #fff, 1528px 1591px #fff,
    1042px 1648px #fff, 1465px 241px #fff, 2430px 46px #fff, 46px 1200px #fff,
    2698px 1541px #fff, 1807px 1931px #fff, 1064px 1338px #fff,
    2738px 1562px #fff, 1142px 131px #fff, 2746px 977px #fff, 172px 78px #fff,
    2292px 1437px #fff, 516px 1711px #fff, 2831px 157px #fff, 895px 914px #fff,
    212px 1420px #fff, 791px 1610px #fff, 1012px 535px #fff, 2653px 248px #fff,
    2752px 539px #fff, 1179px 1228px #fff, 2020px 368px #fff, 349px 1792px #fff,
    2024px 1804px #fff, 500px 391px #fff, 412px 1823px #fff, 29px 790px #fff,
    611px 1096px #fff, 2412px 1868px #fff, 1191px 534px #fff, 2051px 1613px #fff,
    1002px 1513px #fff, 2327px 483px #fff, 1226px 761px #fff, 567px 28px #fff,
    2868px 1590px #fff, 182px 1331px #fff, 2190px 1902px #fff, 640px 667px #fff,
    301px 657px #fff, 1310px 451px #fff, 2464px 1683px #fff, 1004px 1322px #fff,
    2997px 399px #fff, 1808px 1808px #fff, 2349px 1313px #fff, 223px 991px #fff,
    692px 1414px #fff, 276px 475px #fff, 887px 1183px #fff, 1649px 785px #fff,
    1517px 559px #fff, 1494px 913px #fff, 593px 179px #fff, 2984px 1685px #fff,
    2605px 301px #fff, 1767px 1447px #fff, 2409px 700px #fff, 217px 1491px #fff,
    1806px 1496px #fff, 21px 841px #fff, 2385px 1741px #fff, 900px 1082px #fff,
    1778px 1840px #fff, 1514px 814px #fff, 2150px 597px #fff, 292px 1478px #fff,
    1794px 1502px #fff, 960px 1973px #fff, 1078px 1522px #fff, 1043px 281px #fff,
    562px 1249px #fff, 2165px 299px #fff, 2765px 700px #fff, 2677px 158px #fff,
    1709px 453px #fff, 113px 1501px #fff, 2520px 1930px #fff, 2734px 765px #fff,
    1390px 946px #fff, 2772px 1501px #fff, 713px 1760px #fff, 1954px 1005px #fff,
    1615px 297px #fff, 267px 375px #fff, 2015px 175px #fff, 2260px 984px #fff,
    1042px 1124px #fff, 782px 43px #fff, 1148px 257px #fff, 636px 769px #fff,
    1146px 802px #fff, 1512px 1439px #fff, 2267px 170px #fff, 2119px 996px #fff,
    1241px 1817px #fff, 2143px 929px #fff, 1720px 944px #fff, 347px 1261px #fff,
    263px 1699px #fff, 1934px 505px #fff, 2300px 866px #fff, 1279px 661px #fff,
    384px 1167px #fff, 15px 750px #fff, 2416px 55px #fff, 2293px 278px #fff,
    2803px 1321px #fff, 899px 295px #fff, 2387px 1534px #fff, 446px 1635px #fff,
    1744px 963px #fff, 1358px 1299px #fff, 2801px 500px #fff, 1157px 1071px #fff,
    2936px 1833px #fff, 402px 1134px #fff, 802px 1469px #fff, 2236px 1169px #fff,
    2819px 495px #fff, 702px 118px #fff, 1093px 950px #fff, 2846px 1570px #fff,
    1948px 591px #fff, 1720px 1659px #fff, 1110px 742px #fff, 350px 880px #fff,
    1795px 1591px #fff, 530px 752px #fff, 2873px 513px #fff, 748px 1529px #fff,
    1930px 85px #fff, 870px 1447px #fff, 1357px 811px #fff, 997px 1478px #fff,
    1785px 1px #fff, 2014px 95px #fff, 2130px 757px #fff, 1706px 1432px #fff,
    1072px 1555px #fff, 2275px 1153px #fff, 1554px 851px #fff, 1597px 39px #fff,
    882px 637px #fff, 75px 539px #fff, 2115px 371px #fff, 324px 547px #fff,
    1264px 586px #fff, 2985px 10px #fff, 2628px 60px #fff, 758px 337px #fff,
    717px 1487px #fff, 2782px 1392px #fff, 2050px 730px #fff, 2381px 1520px #fff,
    2717px 32px #fff, 355px 1130px #fff, 981px 1494px #fff, 2798px 1509px #fff,
    1903px 1534px #fff, 568px 561px #fff, 538px 52px #fff, 713px 1098px #fff,
    988px 229px #fff, 115px 394px #fff, 1903px 1524px #fff, 1613px 602px #fff,
    1117px 1149px #fff, 2730px 1309px #fff, 2842px 868px #fff, 977px 1563px #fff,
    2584px 1447px #fff, 1388px 1302px #fff, 864px 588px #fff, 550px 950px #fff,
    882px 1545px #fff, 2948px 72px #fff, 2307px 1832px #fff, 1184px 896px #fff,
    899px 258px #fff, 2959px 1282px #fff, 1585px 799px #fff, 2589px 1857px #fff,
    1411px 1375px #fff, 1708px 701px #fff, 2762px 416px #fff, 824px 1790px #fff,
    2650px 416px #fff, 2891px 1903px #fff, 2364px 874px #fff, 385px 1404px #fff,
    1399px 238px #fff, 2194px 1365px #fff, 416px 714px #fff, 2722px 1952px #fff,
    2737px 974px #fff, 373px 1746px #fff, 2494px 1535px #fff, 287px 826px #fff,
    617px 1455px #fff, 2921px 1243px #fff, 2749px 1561px #fff,
    1755px 1464px #fff, 887px 1633px #fff, 103px 1141px #fff, 806px 719px #fff,
    2783px 1715px #fff, 1030px 1983px #fff, 252px 823px #fff, 472px 1262px #fff,
    289px 604px #fff, 1687px 1964px #fff, 230px 958px #fff, 1555px 1260px #fff,
    1283px 1335px #fff, 596px 1550px #fff, 2963px 990px #fff, 1932px 1048px #fff,
    710px 191px #fff, 41px 503px #fff, 1988px 1210px #fff, 156px 1569px #fff,
    1184px 660px #fff, 197px 1688px #fff, 2653px 1430px #fff, 627px 593px #fff,
    2464px 1839px #fff, 2877px 928px #fff, 380px 851px #fff, 2986px 487px #fff,
    403px 1554px #fff, 1586px 632px #fff, 1688px 91px #fff, 1378px 315px #fff,
    1184px 44px #fff, 85px 446px #fff, 2559px 1395px #fff, 1234px 1129px #fff,
    1536px 338px #fff, 1883px 1469px #fff, 621px 404px #fff, 1511px 1050px #fff,
    1089px 1793px #fff, 565px 408px #fff, 1825px 1011px #fff, 995px 1501px #fff,
    1400px 981px #fff, 1336px 171px #fff, 741px 59px #fff, 114px 1858px #fff,
    1332px 1353px #fff, 1896px 1608px #fff, 1899px 444px #fff, 1636px 394px #fff,
    986px 1816px #fff, 2853px 1900px #fff, 1286px 1075px #fff, 2128px 374px #fff,
    2343px 662px #fff, 244px 1055px #fff, 1885px 556px #fff, 2278px 1515px #fff,
    2832px 1253px #fff, 1031px 822px #fff, 1554px 509px #fff, 2413px 436px #fff,
    192px 161px #fff, 2303px 474px #fff, 1348px 1608px #fff, 2706px 472px #fff,
    2329px 918px #fff, 1958px 1987px #fff, 619px 1855px #fff, 2344px 1699px #fff,
    2932px 736px #fff, 881px 775px #fff, 1764px 524px #fff, 1912px 1717px #fff,
    2147px 1864px #fff, 1954px 1174px #fff, 2851px 1453px #fff,
    2204px 254px #fff, 2965px 590px #fff, 2770px 1733px #fff, 2393px 438px #fff,
    863px 1105px #fff, 888px 851px #fff, 2414px 206px #fff, 1176px 1915px #fff,
    10px 1629px #fff, 1105px 1683px #fff, 2179px 437px #fff, 815px 720px #fff,
    2033px 1823px #fff, 1159px 389px #fff, 1095px 144px #fff, 2812px 1697px #fff,
    1989px 804px #fff, 28px 935px #fff, 2458px 1004px #fff, 2907px 1298px #fff,
    789px 236px #fff, 997px 1589px #fff, 2156px 1246px #fff, 2316px 1639px #fff,
    827px 1792px #fff, 1650px 1014px #fff, 2871px 1503px #fff, 2596px 511px #fff,
    94px 49px #fff, 376px 1881px #fff, 987px 948px #fff, 204px 816px #fff,
    316px 750px #fff, 311px 335px #fff, 2793px 1272px #fff, 2920px 644px #fff,
    1455px 1594px #fff, 1988px 412px #fff, 524px 999px #fff, 1841px 400px #fff,
    1697px 53px #fff, 657px 1184px #fff, 357px 724px #fff, 2615px 1118px #fff,
    134px 478px #fff, 2343px 408px #fff, 486px 1730px #fff, 1697px 1690px #fff,
    263px 1122px #fff, 2435px 1571px #fff, 577px 145px #fff, 563px 381px #fff,
    2397px 1164px #fff, 461px 563px #fff, 1469px 1099px #fff, 847px 146px #fff,
    1859px 1328px #fff, 2209px 1732px #fff, 1765px 1340px #fff,
    1315px 1374px #fff, 2719px 456px #fff, 1257px 457px #fff, 54px 463px #fff,
    670px 418px #fff, 2450px 1786px #fff, 2207px 109px #fff, 119px 1235px #fff,
    928px 1364px #fff, 2345px 1983px #fff, 1470px 355px #fff, 2965px 1661px #fff,
    369px 335px #fff, 2969px 844px #fff, 1996px 1071px #fff, 819px 185px #fff,
    300px 494px #fff, 2272px 1474px #fff, 1497px 55px #fff, 1516px 487px #fff,
    856px 1750px #fff, 535px 331px #fff, 697px 1961px #fff, 1085px 297px #fff,
    1642px 1052px #fff, 2310px 1945px #fff, 1461px 1150px #fff,
    2074px 1530px #fff, 724px 1054px #fff, 2719px 1382px #fff, 599px 44px #fff,
    715px 299px #fff, 202px 821px #fff, 1683px 461px #fff, 2474px 310px #fff,
    1623px 905px #fff, 758px 1564px #fff, 23px 1776px #fff, 219px 102px #fff,
    1237px 858px #fff, 2704px 1098px #fff, 2212px 692px #fff, 2729px 1069px #fff,
    1251px 862px #fff, 179px 1260px #fff, 1841px 836px #fff, 1563px 1739px #fff,
    2818px 1725px #fff, 1711px 1606px #fff, 303px 336px #fff, 2268px 1288px #fff,
    2835px 1799px #fff, 416px 1487px #fff, 1249px 1416px #fff, 2276px 229px #fff,
    1085px 1053px #fff, 2719px 1815px #fff, 2849px 1462px #fff,
    2356px 1100px #fff, 1884px 1409px #fff, 437px 574px #fff, 2346px 75px #fff,
    1602px 1151px #fff, 2335px 838px #fff, 2675px 184px #fff, 2107px 1428px #fff,
    2350px 1458px #fff, 375px 12px #fff, 718px 395px #fff, 187px 1402px #fff,
    2430px 828px #fff, 622px 1284px #fff, 1309px 1770px #fff, 1322px 1658px #fff,
    1758px 414px #fff, 528px 1373px #fff, 2664px 628px #fff, 1480px 1027px #fff,
    2900px 932px #fff, 1502px 1658px #fff, 648px 1502px #fff, 852px 960px #fff,
    1905px 991px #fff, 275px 407px #fff, 1822px 197px #fff, 1383px 239px #fff,
    1813px 833px #fff, 2832px 511px #fff, 21px 721px #fff, 645px 1007px #fff,
    2496px 1756px #fff, 2024px 461px #fff, 199px 1858px #fff, 245px 500px #fff,
    2351px 1432px #fff, 167px 2000px #fff, 711px 1674px #fff, 1743px 1227px #fff,
    836px 726px #fff, 1371px 163px #fff, 2530px 312px #fff, 1054px 421px #fff,
    2616px 1184px #fff, 2366px 1323px #fff, 433px 279px #fff, 1716px 990px #fff,
    1259px 643px #fff, 294px 232px #fff, 2724px 701px #fff, 2452px 1603px #fff,
    399px 1210px #fff, 327px 1029px #fff, 317px 1070px #fff, 787px 1396px #fff,
    477px 255px #fff, 1726px 1802px #fff, 2884px 1455px #fff, 2947px 1025px #fff,
    2509px 1061px #fff, 1485px 1505px #fff, 594px 1267px #fff, 2036px 936px #fff,
    659px 631px #fff, 1315px 45px #fff, 2494px 1767px #fff, 847px 1605px #fff,
    342px 1640px #fff, 1607px 1754px #fff, 1845px 219px #fff, 1935px 1160px #fff,
    1701px 22px #fff, 2992px 1773px #fff, 1349px 6px #fff, 1275px 864px #fff,
    227px 1325px #fff, 2031px 869px #fff, 494px 362px #fff, 510px 1957px #fff,
    1506px 1818px #fff, 987px 654px #fff, 753px 1174px #fff, 2685px 110px #fff,
    1096px 237px #fff, 1265px 68px #fff, 1369px 1719px #fff, 1362px 708px #fff,
    808px 1198px #fff, 2284px 1914px #fff, 1882px 852px #fff, 1354px 1083px #fff,
    747px 1945px #fff, 1631px 1910px #fff, 2062px 1878px #fff, 671px 262px #fff,
    356px 359px #fff, 782px 69px #fff, 893px 1267px #fff, 1275px 1870px #fff,
    2747px 981px #fff, 1117px 82px #fff, 218px 415px #fff, 753px 1970px #fff,
    1085px 1399px #fff, 1513px 299px #fff, 2067px 1438px #fff,
    2415px 1404px #fff, 2430px 912px #fff, 150px 525px #fff, 1142px 710px #fff,
    323px 877px #fff, 2575px 4px #fff, 1602px 236px #fff, 202px 1811px #fff,
    1751px 137px #fff, 2461px 1965px #fff, 2911px 417px #fff, 205px 68px #fff,
    57px 1485px #fff, 702px 1576px #fff, 1060px 1845px #fff, 702px 22px #fff,
    1215px 1736px #fff, 446px 1017px #fff, 2278px 1801px #fff, 254px 19px #fff,
    241px 773px #fff, 957px 974px #fff, 1652px 1127px #fff, 1156px 819px #fff,
    2281px 1586px #fff, 1016px 1592px #fff, 132px 1258px #fff, 1716px 62px #fff,
    2729px 1849px #fff, 87px 536px #fff, 989px 1760px #fff, 2599px 352px #fff,
    273px 1398px #fff, 1882px 1540px #fff, 520px 1266px #fff, 2854px 60px #fff,
    1080px 1553px #fff, 976px 1243px #fff, 660px 979px #fff, 208px 1347px #fff,
    604px 1269px #fff, 988px 1232px #fff, 875px 1678px #fff, 1299px 1396px #fff,
    2371px 1115px #fff, 1217px 1423px #fff, 55px 1463px #fff, 747px 55px #fff,
    2930px 965px #fff, 1898px 86px #fff, 1909px 964px #fff, 1439px 896px #fff,
    1779px 1839px #fff, 398px 640px #fff, 2818px 1847px #fff, 2059px 243px #fff,
    1866px 1262px #fff, 314px 528px #fff, 1328px 975px #fff, 1500px 1071px #fff,
    1595px 1337px #fff, 905px 696px #fff, 1684px 449px #fff, 97px 72px #fff;
}

#stars2 {
  background-attachment: fixed;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 770px 1701px #fff, 2953px 1709px #fff, 143px 111px #fff,
    1275px 1160px #fff, 1708px 1698px #fff, 813px 373px #fff, 2991px 1510px #fff,
    1469px 95px #fff, 2278px 1226px #fff, 2317px 278px #fff, 2967px 1839px #fff,
    2458px 16px #fff, 2727px 699px #fff, 1452px 1725px #fff, 596px 1588px #fff,
    1333px 1440px #fff, 1776px 212px #fff, 1637px 1767px #fff,
    1414px 1800px #fff, 364px 1772px #fff, 2647px 107px #fff, 2779px 1744px #fff,
    2662px 162px #fff, 1142px 1593px #fff, 2216px 1095px #fff,
    2600px 1540px #fff, 2654px 1883px #fff, 1177px 174px #fff, 1017px 489px #fff,
    2520px 436px #fff, 233px 1092px #fff, 582px 1350px #fff, 552px 766px #fff,
    1737px 151px #fff, 1574px 194px #fff, 1091px 249px #fff, 1619px 737px #fff,
    541px 532px #fff, 2027px 746px #fff, 767px 714px #fff, 160px 941px #fff,
    641px 973px #fff, 1915px 404px #fff, 1379px 515px #fff, 38px 567px #fff,
    2837px 868px #fff, 191px 14px #fff, 1947px 115px #fff, 800px 605px #fff,
    316px 743px #fff, 1972px 1239px #fff, 1235px 1435px #fff, 1728px 1643px #fff,
    2829px 94px #fff, 1928px 1636px #fff, 2486px 260px #fff, 2187px 8px #fff,
    286px 1052px #fff, 1846px 1073px #fff, 1088px 1514px #fff,
    1161px 1752px #fff, 1349px 884px #fff, 1024px 384px #fff, 2751px 177px #fff,
    2512px 556px #fff, 2421px 1982px #fff, 2072px 1464px #fff, 112px 511px #fff,
    1794px 241px #fff, 2197px 146px #fff, 1391px 1683px #fff, 2713px 854px #fff,
    1817px 538px #fff, 1246px 1732px #fff, 2338px 609px #fff, 2616px 1440px #fff,
    176px 314px #fff, 271px 1459px #fff, 2549px 1795px #fff, 877px 324px #fff,
    755px 834px #fff, 840px 922px #fff, 2813px 1608px #fff, 184px 1474px #fff,
    1146px 572px #fff, 2106px 377px #fff, 1181px 1730px #fff, 1161px 1372px #fff,
    1853px 1990px #fff, 1480px 1922px #fff, 2583px 1959px #fff,
    1069px 1726px #fff, 1049px 714px #fff, 1767px 623px #fff, 2338px 509px #fff,
    2093px 1706px #fff, 2040px 1302px #fff, 1110px 1881px #fff, 435px 95px #fff,
    775px 858px #fff, 1006px 1946px #fff, 46px 925px #fff, 1002px 1898px #fff,
    650px 1351px #fff, 1337px 320px #fff, 934px 1719px #fff, 2887px 511px #fff,
    49px 1276px #fff, 1136px 298px #fff, 1827px 1799px #fff, 2944px 1066px #fff,
    2593px 1506px #fff, 17px 1302px #fff, 2218px 1456px #fff, 1407px 1047px #fff,
    1770px 1799px #fff, 2605px 931px #fff, 2353px 462px #fff, 2300px 1606px #fff,
    1764px 825px #fff, 1193px 556px #fff, 883px 624px #fff, 1031px 1334px #fff,
    1508px 1075px #fff, 1933px 199px #fff, 986px 602px #fff, 1675px 1430px #fff,
    598px 1172px #fff, 539px 339px #fff, 317px 1848px #fff, 2310px 1184px #fff,
    151px 1013px #fff, 2488px 1719px #fff, 828px 345px #fff, 2778px 511px #fff,
    1798px 67px #fff, 1550px 779px #fff, 1361px 880px #fff, 1155px 170px #fff,
    1823px 403px #fff, 36px 1545px #fff, 1131px 355px #fff, 2038px 868px #fff,
    2490px 1902px #fff, 1513px 1722px #fff, 2054px 1441px #fff,
    1148px 247px #fff, 2212px 1306px #fff, 1861px 1467px #fff, 924px 110px #fff,
    313px 1043px #fff, 1576px 1840px #fff, 1047px 1870px #fff, 379px 1145px #fff,
    1300px 650px #fff, 2128px 1149px #fff, 2746px 734px #fff, 822px 1241px #fff,
    1801px 1271px #fff, 497px 1268px #fff, 1307px 713px #fff, 2074px 296px #fff,
    903px 1465px #fff, 2863px 1408px #fff, 1848px 1094px #fff, 2557px 274px #fff,
    1198px 447px #fff, 2431px 1335px #fff, 179px 69px #fff, 790px 1718px #fff,
    1468px 693px #fff, 1127px 1533px #fff, 34px 1061px #fff, 1608px 465px #fff,
    71px 1269px #fff, 637px 1249px #fff, 980px 689px #fff, 381px 183px #fff,
    1466px 1329px #fff, 559px 793px #fff, 70px 913px #fff, 624px 1995px #fff,
    2718px 1481px #fff, 1461px 1564px #fff, 2778px 294px #fff, 794px 854px #fff,
    524px 1753px #fff, 45px 1512px #fff, 2613px 366px #fff, 156px 1668px #fff,
    604px 480px #fff, 2604px 1853px #fff, 2567px 88px #fff, 2917px 1378px #fff,
    705px 1993px #fff, 669px 1719px #fff, 878px 1982px #fff, 2466px 1163px #fff,
    1254px 1894px #fff, 1475px 730px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  background-attachment: fixed;
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 770px 1701px #fff, 2953px 1709px #fff, 143px 111px #fff,
    1275px 1160px #fff, 1708px 1698px #fff, 813px 373px #fff, 2991px 1510px #fff,
    1469px 95px #fff, 2278px 1226px #fff, 2317px 278px #fff, 2967px 1839px #fff,
    2458px 16px #fff, 2727px 699px #fff, 1452px 1725px #fff, 596px 1588px #fff,
    1333px 1440px #fff, 1776px 212px #fff, 1637px 1767px #fff,
    1414px 1800px #fff, 364px 1772px #fff, 2647px 107px #fff, 2779px 1744px #fff,
    2662px 162px #fff, 1142px 1593px #fff, 2216px 1095px #fff,
    2600px 1540px #fff, 2654px 1883px #fff, 1177px 174px #fff, 1017px 489px #fff,
    2520px 436px #fff, 233px 1092px #fff, 582px 1350px #fff, 552px 766px #fff,
    1737px 151px #fff, 1574px 194px #fff, 1091px 249px #fff, 1619px 737px #fff,
    541px 532px #fff, 2027px 746px #fff, 767px 714px #fff, 160px 941px #fff,
    641px 973px #fff, 1915px 404px #fff, 1379px 515px #fff, 38px 567px #fff,
    2837px 868px #fff, 191px 14px #fff, 1947px 115px #fff, 800px 605px #fff,
    316px 743px #fff, 1972px 1239px #fff, 1235px 1435px #fff, 1728px 1643px #fff,
    2829px 94px #fff, 1928px 1636px #fff, 2486px 260px #fff, 2187px 8px #fff,
    286px 1052px #fff, 1846px 1073px #fff, 1088px 1514px #fff,
    1161px 1752px #fff, 1349px 884px #fff, 1024px 384px #fff, 2751px 177px #fff,
    2512px 556px #fff, 2421px 1982px #fff, 2072px 1464px #fff, 112px 511px #fff,
    1794px 241px #fff, 2197px 146px #fff, 1391px 1683px #fff, 2713px 854px #fff,
    1817px 538px #fff, 1246px 1732px #fff, 2338px 609px #fff, 2616px 1440px #fff,
    176px 314px #fff, 271px 1459px #fff, 2549px 1795px #fff, 877px 324px #fff,
    755px 834px #fff, 840px 922px #fff, 2813px 1608px #fff, 184px 1474px #fff,
    1146px 572px #fff, 2106px 377px #fff, 1181px 1730px #fff, 1161px 1372px #fff,
    1853px 1990px #fff, 1480px 1922px #fff, 2583px 1959px #fff,
    1069px 1726px #fff, 1049px 714px #fff, 1767px 623px #fff, 2338px 509px #fff,
    2093px 1706px #fff, 2040px 1302px #fff, 1110px 1881px #fff, 435px 95px #fff,
    775px 858px #fff, 1006px 1946px #fff, 46px 925px #fff, 1002px 1898px #fff,
    650px 1351px #fff, 1337px 320px #fff, 934px 1719px #fff, 2887px 511px #fff,
    49px 1276px #fff, 1136px 298px #fff, 1827px 1799px #fff, 2944px 1066px #fff,
    2593px 1506px #fff, 17px 1302px #fff, 2218px 1456px #fff, 1407px 1047px #fff,
    1770px 1799px #fff, 2605px 931px #fff, 2353px 462px #fff, 2300px 1606px #fff,
    1764px 825px #fff, 1193px 556px #fff, 883px 624px #fff, 1031px 1334px #fff,
    1508px 1075px #fff, 1933px 199px #fff, 986px 602px #fff, 1675px 1430px #fff,
    598px 1172px #fff, 539px 339px #fff, 317px 1848px #fff, 2310px 1184px #fff,
    151px 1013px #fff, 2488px 1719px #fff, 828px 345px #fff, 2778px 511px #fff,
    1798px 67px #fff, 1550px 779px #fff, 1361px 880px #fff, 1155px 170px #fff,
    1823px 403px #fff, 36px 1545px #fff, 1131px 355px #fff, 2038px 868px #fff,
    2490px 1902px #fff, 1513px 1722px #fff, 2054px 1441px #fff,
    1148px 247px #fff, 2212px 1306px #fff, 1861px 1467px #fff, 924px 110px #fff,
    313px 1043px #fff, 1576px 1840px #fff, 1047px 1870px #fff, 379px 1145px #fff,
    1300px 650px #fff, 2128px 1149px #fff, 2746px 734px #fff, 822px 1241px #fff,
    1801px 1271px #fff, 497px 1268px #fff, 1307px 713px #fff, 2074px 296px #fff,
    903px 1465px #fff, 2863px 1408px #fff, 1848px 1094px #fff, 2557px 274px #fff,
    1198px 447px #fff, 2431px 1335px #fff, 179px 69px #fff, 790px 1718px #fff,
    1468px 693px #fff, 1127px 1533px #fff, 34px 1061px #fff, 1608px 465px #fff,
    71px 1269px #fff, 637px 1249px #fff, 980px 689px #fff, 381px 183px #fff,
    1466px 1329px #fff, 559px 793px #fff, 70px 913px #fff, 624px 1995px #fff,
    2718px 1481px #fff, 1461px 1564px #fff, 2778px 294px #fff, 794px 854px #fff,
    524px 1753px #fff, 45px 1512px #fff, 2613px 366px #fff, 156px 1668px #fff,
    604px 480px #fff, 2604px 1853px #fff, 2567px 88px #fff, 2917px 1378px #fff,
    705px 1993px #fff, 669px 1719px #fff, 878px 1982px #fff, 2466px 1163px #fff,
    1254px 1894px #fff, 1475px 730px #fff;
}

#stars3 {
  background-attachment: fixed;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1790px 1145px #fff, 1141px 787px #fff, 796px 1359px #fff,
    102px 321px #fff, 2132px 1623px #fff, 2190px 156px #fff, 1071px 435px #fff,
    2957px 1305px #fff, 567px 1430px #fff, 1205px 367px #fff, 2428px 279px #fff,
    589px 1963px #fff, 2589px 1160px #fff, 330px 1861px #fff, 2620px 726px #fff,
    625px 112px #fff, 2886px 723px #fff, 500px 1033px #fff, 496px 1624px #fff,
    718px 38px #fff, 262px 1846px #fff, 1122px 1110px #fff, 1166px 485px #fff,
    506px 40px #fff, 384px 835px #fff, 393px 1236px #fff, 1115px 607px #fff,
    2706px 1690px #fff, 2828px 1830px #fff, 2291px 1304px #fff, 719px 641px #fff,
    1543px 68px #fff, 2040px 589px #fff, 1006px 525px #fff, 2569px 902px #fff,
    224px 1117px #fff, 156px 1346px #fff, 2753px 1202px #fff, 2178px 1122px #fff,
    2308px 173px #fff, 90px 1544px #fff, 314px 696px #fff, 1143px 126px #fff,
    2092px 629px #fff, 1936px 1393px #fff, 162px 1927px #fff, 602px 166px #fff,
    2788px 67px #fff, 1811px 887px #fff, 2985px 1224px #fff, 669px 1256px #fff,
    1678px 235px #fff, 2458px 1046px #fff, 33px 220px #fff, 2341px 1767px #fff,
    2359px 513px #fff, 1674px 1883px #fff, 2172px 1107px #fff, 903px 258px #fff,
    981px 622px #fff, 1479px 1966px #fff, 1549px 991px #fff, 1022px 549px #fff,
    270px 1833px #fff, 5px 826px #fff, 413px 1799px #fff, 1238px 1434px #fff,
    1477px 798px #fff, 517px 955px #fff, 1189px 214px #fff, 122px 1902px #fff,
    259px 619px #fff, 654px 1107px #fff, 959px 1760px #fff, 2217px 956px #fff,
    2714px 538px #fff, 2081px 415px #fff, 2526px 761px #fff, 1538px 1334px #fff,
    2102px 1189px #fff, 1836px 1355px #fff, 2685px 1970px #fff,
    1407px 216px #fff, 1778px 794px #fff, 803px 1477px #fff, 2452px 793px #fff,
    954px 1745px #fff, 1908px 1275px #fff, 919px 1619px #fff, 1291px 745px #fff,
    1407px 868px #fff, 2640px 1640px #fff, 1090px 1289px #fff,
    1255px 1758px #fff, 1156px 298px #fff, 2711px 1466px #fff, 215px 417px #fff,
    244px 809px #fff, 1477px 902px #fff, 2488px 1280px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  background-attachment: fixed;
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1790px 1145px #fff, 1141px 787px #fff, 796px 1359px #fff,
    102px 321px #fff, 2132px 1623px #fff, 2190px 156px #fff, 1071px 435px #fff,
    2957px 1305px #fff, 567px 1430px #fff, 1205px 367px #fff, 2428px 279px #fff,
    589px 1963px #fff, 2589px 1160px #fff, 330px 1861px #fff, 2620px 726px #fff,
    625px 112px #fff, 2886px 723px #fff, 500px 1033px #fff, 496px 1624px #fff,
    718px 38px #fff, 262px 1846px #fff, 1122px 1110px #fff, 1166px 485px #fff,
    506px 40px #fff, 384px 835px #fff, 393px 1236px #fff, 1115px 607px #fff,
    2706px 1690px #fff, 2828px 1830px #fff, 2291px 1304px #fff, 719px 641px #fff,
    1543px 68px #fff, 2040px 589px #fff, 1006px 525px #fff, 2569px 902px #fff,
    224px 1117px #fff, 156px 1346px #fff, 2753px 1202px #fff, 2178px 1122px #fff,
    2308px 173px #fff, 90px 1544px #fff, 314px 696px #fff, 1143px 126px #fff,
    2092px 629px #fff, 1936px 1393px #fff, 162px 1927px #fff, 602px 166px #fff,
    2788px 67px #fff, 1811px 887px #fff, 2985px 1224px #fff, 669px 1256px #fff,
    1678px 235px #fff, 2458px 1046px #fff, 33px 220px #fff, 2341px 1767px #fff,
    2359px 513px #fff, 1674px 1883px #fff, 2172px 1107px #fff, 903px 258px #fff,
    981px 622px #fff, 1479px 1966px #fff, 1549px 991px #fff, 1022px 549px #fff,
    270px 1833px #fff, 5px 826px #fff, 413px 1799px #fff, 1238px 1434px #fff,
    1477px 798px #fff, 517px 955px #fff, 1189px 214px #fff, 122px 1902px #fff,
    259px 619px #fff, 654px 1107px #fff, 959px 1760px #fff, 2217px 956px #fff,
    2714px 538px #fff, 2081px 415px #fff, 2526px 761px #fff, 1538px 1334px #fff,
    2102px 1189px #fff, 1836px 1355px #fff, 2685px 1970px #fff,
    1407px 216px #fff, 1778px 794px #fff, 803px 1477px #fff, 2452px 793px #fff,
    954px 1745px #fff, 1908px 1275px #fff, 919px 1619px #fff, 1291px 745px #fff,
    1407px 868px #fff, 2640px 1640px #fff, 1090px 1289px #fff,
    1255px 1758px #fff, 1156px 298px #fff, 2711px 1466px #fff, 215px 417px #fff,
    244px 809px #fff, 1477px 902px #fff, 2488px 1280px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
